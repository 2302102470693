// React
import React from 'react'

// React Router
import { withRouter } from 'react-router-dom'

// Material UI
import { Drawer, List, Typography, IconButton, withStyles, Menu, MenuItem, Link, SvgIcon } from '@material-ui/core'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuIcon from '@material-ui/icons/Menu'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import MessageIcon from '@material-ui/icons/Message'
import HistoryIcon from '@material-ui/icons/History'
import MapIcon from '@material-ui/icons/Map'
import ComputerIcon from '@material-ui/icons/Computer'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import CloseIcon from '@material-ui/icons/Close'

//components
import Timer from './timer'

// Utilities
import clsx from 'clsx'
import Logo from '../../assets/logo.png'
import theme from '../../utilities/theme'
import RadioPlayer from '../general/RadioPlayer'
import NotificationsMenu from './bellNotification/NotificationsMenu'

const PageContainsNavigation = (route) => {
    switch (route) {
        case 'activate':
            return false
        case 'activate-account':
            return false
        case 'public-audio-dispatch':
            return false
        case 'long-dispatch-message-public-view':
            return false
        case 'aww-sign-up':
            return false
        case 'reset-password':
            return false
        case 'login':
            return false
        case 'info-change':
            return false
        case '':
            return false
        default:
            return true
    }
}

const formatRouteName = (route) => {
    switch (route) {
        case 'dashboard':
            return 'Dashboard'
        case 'firetextAnnouncements':
            return 'Announcements'
        case 'chat':
            return 'Live Chat'
        case 'history':
            return 'History'
        case 'maps':
            return 'Maps'
        case 'settings':
            return 'Settings'
        default:
            return null
    }
}

const styles = () => ({
    navbarOpen: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 250,
        paddingRight: 16,
        height: 50,
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.dark,
        boxShadow: 'none'
    },
    navbarClose: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 50,
        paddingRight: 16,
        height: 50,
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.dark,
        boxShadow: 'none'
    },
    drawerOpen: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.dark,
        border: 'none',
        width: 250,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    drawerClose: {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.dark,
        border: 'none',
        width: 57,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden'
    },
    logoContainerOpen: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingRight: 16,
        paddingTop: 32,
        paddingBottom: 32
    },
    logoContainerClose: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 16,
        paddingBottom: 8
    },
    toggleMenuWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    routeTitle: {
        color: theme.palette.primary.light,
        margin: 0,
        fontSize: 16
    },
    activeIcon: {
        color: theme.palette.primary.main
    },
    icon: {
        color: theme.palette.primary.light
    }
})

const initialState = {
    anchorElement: null
}

class Navigation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            user: props.user
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
            this.setState({ user: this.props.user })
        }
    }

    handleDropdownClick = (e) => {
        const { anchorElement } = this.state

        if (anchorElement) {
            this.setState({ anchorElement: null })
        } else {
            this.setState({ anchorElement: e.currentTarget })
        }
    }

    render() {
        const { classes, menuOpen, toggleMenu, signOut, radioChannel, playRadio, pauseRadio, stopRadio, accessToken, socket, pushToTalkDepartments } = this.props
        const { user, anchorElement } = this.state
        let route = this.props.location.pathname.split('/')[1]

        const hasChatEnabledDepartment = user?.departmentObjects?.filter(dept => dept.featuresPermission.chat === true).length > 0

        const firetextAnnouncementsEnabledDepartments = user?.departmentObjects?.filter(dept => dept.featuresPermission.firetextAnnouncements === true)
        const hasFiretextAnnouncementsEnabledDepartments = !!firetextAnnouncementsEnabledDepartments?.length && user.departments.filter(dept => (dept.role === 'departmentAdmin' && (firetextAnnouncementsEnabledDepartments.findIndex(d => d._id === dept.departmentId) !== -1))).length > 0

        console.log(pushToTalkDepartments?.length, pushToTalkDepartments)

        if (PageContainsNavigation(route)) {
            return (
                <nav
                    style={{ position: 'sticky', top: 0, zIndex: 1000 }}
                >
                    <div
                        className={clsx(classes.drawer, {
                            [classes.navbarOpen]: menuOpen,
                            [classes.navbarClose]: !menuOpen,
                        })}
                    >
                        <div
                            className={classes.toggleMenuWrapper}
                        >
                            <IconButton
                                onClick={toggleMenu}
                            >
                                {menuOpen ?
                                    <MenuOpenIcon
                                        className={classes.icon}
                                    />
                                    :
                                    <MenuIcon
                                        className={classes.icon}
                                    />
                                }
                            </IconButton>
                            <Typography
                                variant='subtitle1'
                                className={classes.routeTitle}
                            >
                                {formatRouteName(route)}
                            </Typography>
                        </div>
                        <div
                            className={classes.toggleMenuWrapper}
                        >
                            <NotificationsMenu
                                user={user}
                                accessToken={accessToken}
                                socket={socket}
                            />
                            {user &&
                                <div
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <Typography>
                                        {user.email}
                                    </Typography>
                                    <Typography
                                        variant='subtitle1'
                                        className={classes.routeTitle}
                                        style={{ marginLeft: 28 }}
                                    >
                                        {user.firstName} {user.lastName}
                                    </Typography>
                                </div>

                            }
                            <IconButton
                                onClick={this.handleDropdownClick}
                            >
                                <MoreVertIcon
                                    className={classes.icon}
                                />
                            </IconButton>
                            <Menu
                                anchorEl={anchorElement}
                                keepMounted
                                open={anchorElement ? true : false}
                                onClose={this.handleDropdownClick}
                                style={{ marginTop: 24 }}
                                disableScrollLock={true}
                            >
                                <MenuItem
                                    onClick={signOut}
                                >
                                    <ListItemIcon>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    Sign out
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: menuOpen,
                            [classes.drawerClose]: !menuOpen,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: menuOpen,
                                [classes.drawerClose]: !menuOpen,
                            }),
                        }}
                    >
                        <div>
                            <div
                                className={clsx(classes.drawer, {
                                    [classes.logoContainerOpen]: menuOpen,
                                    [classes.logoContainerClose]: !menuOpen,
                                })}
                            >
                                {menuOpen ?
                                    <div
                                        style={{ display: 'flex' }}
                                    >
                                        <img
                                            src={Logo}
                                            width={45}
                                            style={{ objectFit: 'contain', marginRight: 8 }}
                                            alt="FireTEXT"
                                        />
                                        <h2>
                                            FireTEXT<sup style={{ fontSize: 16, position: 'relative', top: -6 }}>®</sup>
                                        </h2>
                                    </div>
                                    :
                                    <img
                                        src={Logo}
                                        width={30}
                                        alt="FireTEXT"
                                    />
                                }
                            </div>
                            {menuOpen &&
                                <Timer />
                            }
                            {radioChannel.playing &&
                                <RadioPlayer
                                    channelId={radioChannel._id}
                                />
                            }
                            <List>
                                {(user?.type === 'superAdmin' || user?.type === 'dispatcher' || (user?.departments?.findIndex(department => department.role === 'departmentAdmin') !== -1)) &&
                                    <ListItem
                                        button
                                        onClick={() => this.props.history.push('/dashboard')}
                                    >
                                        <ListItemIcon>
                                            <DashboardIcon
                                                style={{ width: 30, height: 30 }}
                                                className={route === 'dashboard' ? classes.activeIcon : classes.icon}
                                            />
                                        </ListItemIcon>
                                        {menuOpen &&
                                            <ListItemText
                                                primary='Dashboard'
                                            />
                                        }
                                    </ListItem>
                                }
                                {!!pushToTalkDepartments?.length ?
                                    <ListItem
                                        button
                                        onClick={() => this.props.history.push('/pttDepartments')}
                                    >
                                        <ListItemIcon>
                                            <SvgIcon
                                                style={{ fontSize: 32 }}
                                                viewBox="3 2 20 20"
                                                className={route === 'pttDepartments' ? classes.activeIcon : classes.icon}
                                            >
                                                <path d="M9,2C8.45,2,8,2.45,8,3c0,5.67,0,11.33,0,17c0,1.11,0.89,2,2,2h5c1.11,0,2-0.89,2-2V9c0-1.11-0.89-2-2-2h-5V3  C10,2.45,9.55,2,9,2 M10,9h5v4h-5V9z"></path>
                                            </SvgIcon>
                                        </ListItemIcon>

                                        {menuOpen &&
                                            <ListItemText
                                                primary='Push To Talk'
                                            />
                                        }
                                    </ListItem>
                                    :
                                    null
                                }
                                {(user?.type === 'superAdmin' || hasFiretextAnnouncementsEnabledDepartments) &&
                                    <ListItem
                                        button
                                        onClick={() => this.props.history.push('/firetextAnnouncements')}
                                    >
                                        <ListItemIcon>
                                            <SvgIcon style={{ fontSize: 32 }} className={route === 'firetextAnnouncements' ? classes.activeIcon : classes.icon}>
                                                <path d="M18 11v2h4v-2h-4zm-2 6.61c.96.71 2.21 1.65 3.2 2.39.4-.53.8-1.07 1.2-1.6-.99-.74-2.24-1.68-3.2-2.4-.4.54-.8 1.08-1.2 1.61zM20.4 5.6c-.4-.53-.8-1.07-1.2-1.6-.99.74-2.24 1.68-3.2 2.4.4.53.8 1.07 1.2 1.6.96-.72 2.21-1.65 3.2-2.4zM4 9c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3V6L8 9H4zm11.5 3c0-1.33-.58-2.53-1.5-3.35v6.69c.92-.81 1.5-2.01 1.5-3.34z"></path>
                                            </SvgIcon>
                                        </ListItemIcon>
                                        {menuOpen &&
                                            <ListItemText
                                                primary='Announcements'
                                            />
                                        }
                                    </ListItem>
                                }
                                {(user?.type === 'superAdmin' || (user?.type !== 'dispatcher' && hasChatEnabledDepartment)) &&
                                    <ListItem
                                        button
                                        onClick={() => this.props.history.push('/chat')}
                                    >
                                        <ListItemIcon>
                                            <MessageIcon
                                                style={{ width: 30, height: 30 }}
                                                className={route === 'chat' ? classes.activeIcon : classes.icon}
                                            />
                                        </ListItemIcon>
                                        {menuOpen &&
                                            <ListItemText
                                                primary='Live Chat'
                                            />
                                        }
                                    </ListItem>
                                }
                                <ListItem
                                    button
                                    onClick={() => this.props.history.push('/history')}
                                >
                                    <ListItemIcon>
                                        <HistoryIcon
                                            style={{ width: 30, height: 30 }}
                                            className={route === 'history' ? classes.activeIcon : classes.icon}
                                        />
                                    </ListItemIcon>
                                    {menuOpen &&
                                        <ListItemText
                                            primary='History'
                                        />
                                    }
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => this.props.history.push('/maps')}
                                >
                                    <ListItemIcon>
                                        <MapIcon
                                            style={{ width: 30, height: 30 }}
                                            className={route === 'maps' ? classes.activeIcon : classes.icon}
                                        />
                                    </ListItemIcon>
                                    {menuOpen &&
                                        <ListItemText
                                            primary='Maps'
                                        />
                                    }
                                </ListItem>
                                {user?.type === 'superAdmin' &&
                                    <ListItem
                                        button
                                        onClick={() => this.props.history.push('/cadDepartments')}
                                    >
                                        <ListItemIcon>
                                            <ComputerIcon
                                                style={{ width: 30, height: 30 }}
                                                className={route === 'cadDepartments' ? classes.activeIcon : classes.icon}
                                            />
                                        </ListItemIcon>
                                        {menuOpen &&
                                            <ListItemText
                                                primary='CAD'
                                            />
                                        }
                                    </ListItem>
                                }
                                <ListItem
                                    button
                                    onClick={() => this.props.history.push('/settings')}
                                >
                                    <ListItemIcon>
                                        <SettingsIcon
                                            style={{ width: 30, height: 30 }}
                                            className={route === 'settings' ? classes.activeIcon : classes.icon}
                                        />
                                    </ListItemIcon>
                                    {menuOpen &&
                                        <ListItemText
                                            primary='Settings'
                                        />
                                    }
                                </ListItem>
                            </List>
                        </div>
                        {menuOpen && radioChannel._id &&
                            <div
                                style={{ display: 'flex', flexDirection: 'column', background: '#00b300', width: '80%', borderRadius: 8, marginBottom: 8 }}
                            >
                                <div
                                    style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
                                >
                                    <Typography
                                        variant='subtitle1'
                                        style={{ marginLeft: 8 }}
                                    >
                                        Radio
                                    </Typography>
                                    {radioChannel.playing ?
                                        <IconButton
                                            onClick={() => pauseRadio(radioChannel)}
                                            style={{ marginLeft: 'auto' }}
                                        >
                                            <PauseIcon
                                                className={classes.icon}
                                            />
                                        </IconButton>
                                        :
                                        <IconButton
                                            onClick={() => playRadio(radioChannel)}
                                            style={{ marginLeft: 'auto' }}
                                        >
                                            <PlayArrowIcon
                                                className={classes.icon}
                                            />
                                        </IconButton>
                                    }

                                    <IconButton
                                        onClick={stopRadio}
                                    >
                                        <CloseIcon
                                            className={classes.icon}
                                        />
                                    </IconButton>
                                </div>

                                <Typography
                                    variant='subtitle1'
                                    style={{ margin: 'auto' }}
                                >
                                    {radioChannel.description}
                                </Typography>

                            </div>
                        }

                        {menuOpen &&
                            <div
                                style={{
                                    width: '100%',
                                    paddingLeft: 16,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Link
                                    underline="always"
                                    variant='body1'
                                    target='_blank'
                                    href='https://firetext.net/contact'
                                    style={{ textUnderlineOffset: 2, marginBottom: 4, color: '#fff' }}
                                >
                                    Submit Feedback
                                </Link>
                                <Link
                                    underline="always"
                                    variant='body1'
                                    target='_blank'
                                    href='https://firetext.net/support'
                                    style={{ textUnderlineOffset: 2, marginBottom: 16, color: '#fff' }}
                                >
                                    Contact Support
                                </Link>

                                <Typography
                                    variant='body1'
                                    style={{ fontWeight: 'bold', color: '#fff', marginBottom: 4 }}
                                >
                                    support@firetext.net
                                </Typography>
                                <Typography
                                    variant='body1'
                                    style={{ fontWeight: 'bold', marginBottom: 16 }}
                                >
                                    +1 (870) 483-2535
                                </Typography>

                                <div
                                    style={{ marginTop: 4, marginBottom: 24 }}
                                >
                                    <Typography
                                        variant="body1"
                                        style={{ color: '#9e9999' }}
                                    >
                                        v{require('../../../package.json').version}
                                    </Typography>
                                    <Typography
                                        variant='body1'
                                        style={{ color: '#9e9999', marginTop: 16 }}
                                    >
                                        Copyright © 2022. FireTEXT® Dispatch Solutions, INC. All Rights Reserved.
                                    </Typography>
                                </div>
                            </div>
                        }
                    </Drawer>
                </nav>
            )
        } else {
            return null
        }
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(Navigation))
