import React from 'react'

// MUI
import { Grid, Typography } from '@material-ui/core'

// Custom
import GeneralCheckboxField from '../../../general/CheckboxField'

const DepartmentFeaturesPermission = ({ department, errors, handleInputChange, loaders }) => {

    return (
        <Grid
            xs={12}
            container
            item
            style={{ marginBottom: 12, paddingBotton: 12 }}
        >

            <Typography
                variant='h6'
                style={{ marginBottom: 24 }}
            >
                Features Permission
            </Typography>

            <Grid
                xs={12}
                container
                item
            >
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Manual Dispatch'
                    statePath={`department.featuresPermission.manualDispatches`}
                    value={department.featuresPermission.manualDispatches}
                    error={errors?.department?.featuresPermission?.manualDispatches}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Audio Dispatch'
                    statePath={`department.featuresPermission.audioDispatches`}
                    value={department.featuresPermission.audioDispatches}
                    error={errors?.department?.featuresPermission?.audioDispatches}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Respond To Dispatch'
                    statePath={`department.featuresPermission.respondDispatches`}
                    value={department.featuresPermission.respondDispatches}
                    error={errors?.department?.featuresPermission?.respondDispatches}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Chat'
                    statePath={`department.featuresPermission.chat`}
                    value={department.featuresPermission.chat}
                    error={errors?.department?.featuresPermission?.chat}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Weather'
                    statePath={`department.featuresPermission.weather`}
                    value={department.featuresPermission.weather}
                    error={errors?.department?.featuresPermission?.weather}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Text Notifications'
                    statePath={`department.featuresPermission.textNotifications`}
                    value={department.featuresPermission.textNotifications}
                    error={errors?.department?.featuresPermission?.textNotifications}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Phone Call Notifications'
                    statePath={`department.featuresPermission.phoneCallNotifications`}
                    value={department.featuresPermission.phoneCallNotifications}
                    error={errors?.department?.featuresPermission?.phoneCallNotifications}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Push Notifications'
                    statePath={`department.featuresPermission.pushNotifications`}
                    value={department.featuresPermission.pushNotifications}
                    error={errors?.department?.featuresPermission?.pushNotifications}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Email Notifications'
                    statePath={`department.featuresPermission.emailNotifications`}
                    value={department.featuresPermission.emailNotifications}
                    error={errors?.department?.featuresPermission?.emailNotifications}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='FireTEXT Announcements'
                    statePath={`department.featuresPermission.firetextAnnouncements`}
                    value={department.featuresPermission.firetextAnnouncements}
                    error={errors?.department?.featuresPermission?.firetextAnnouncements}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Push To Talk (Mobile)'
                    statePath={`department.featuresPermission.pttMobile`}
                    value={department.featuresPermission.pttMobile}
                    error={errors?.department?.featuresPermission?.pttMobile}
                    handleInputChange={handleInputChange}
                    loader={false}
                />
                <GeneralCheckboxField
                    gridStyle={{ marginTop: 8 }}
                    width={12}
                    size='small'
                    label='Push To Talk (Web)'
                    statePath={`department.featuresPermission.pttWeb`}
                    value={department.featuresPermission.pttWeb}
                    error={errors?.department?.featuresPermission?.pttWeb}
                    handleInputChange={handleInputChange}
                    loader={false}
                />

            </Grid>

        </Grid>
    )
}

export default DepartmentFeaturesPermission;